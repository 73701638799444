<script>
/**
 * New budget listing
 */
import BudgetService from "@/services/BudgetService";
import ContractDetailService from "@/services/ContractDetailService";
import VBudgetForm from "@/components/Forms/VNewBudgetForm";
import dayjs from "dayjs";
import { EventBus } from "@/libraries/EventBus";

export default {
    name: "VBudgets",
    components: {
        VBudgetForm
    },
    props: {
        contract: {
            type: Object,
            description: "Contract"
        },
        year: {},
        budgetColors: {}
    },
    data() {
        return {
            budgets: [],
            meta: {},
            loading: false,
            editing: false,
            editContext: null,
            connectedContext: false // using colors to check if initially the budget is connected somwhere
        };
    },
    computed: {
        currentYear() {
            return dayjs().format("YYYY");
        }
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.fetchData();
        EventBus.$on("reload-budgets", this.fetchData);
    },
    beforeDestroy() {
        EventBus.$off("reload-budgets");
    },
    methods: {
        /**
         * Fetch data for a given year
         */
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await BudgetService.GET_budgets(
                    this.contract.id,
                    this.year
                );
                this.budgets = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("FE E E", e);
            }
            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            console.log("Budget context", ctx);
            this.editContext = ctx;
            this.connectedContext = ctx.id in this.budgetColors;
            this.editing = true;
        },
        async setOnAll(budget_id) {
            this.$swal({
                icon: "warning",
                text: this.$t(
                    "budget.are_you_sure_you_want_set_this_budget_on_all_periods"
                ),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        const r = await ContractDetailService.PUT_setBudgetLinks(
                            this.contract.id,
                            budget_id,
                            this.year
                        );
                        this.$emit("reload");
                    } catch (e) {
                        console.log("setalllinks", e);
                    }
                }
            });
        },
        /**
         *
         */
        async closeSave() {
            this.editing = false;
            this.$emit("reload");
            await this.fetchData();
        }
    }
};
</script>

<template>
    <div>
        <div class="px-lr" id="contract-budget-box">
            <div class="mg-table-title">
                <div class="mg-table-title__title">
                    <h3>{{ $t("budget.budgets") }}</h3>
                </div>
                <div class="mg-table-title__actions">
                    <div class="mg-table-title__actions__left">
                        <span
                            v-if="ACL_RW"
                            class="m-click text-primary"
                            @click="setContext({ empty: true })"
                            ><i class="far fa-plus"></i>
                            {{ $t("budget.add_budget") }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="small mb-3">
                <i class="far fa-info mr-1"></i>
                {{ $t("budget.values_are_per_period_text") }}
            </div>
        </div>
        <div class="mg-budget">
            <v-loader :loading="loading" v-if="loading" />
            <template v-else-if="budgets.length">
                <div class="table-responsive">
                    <v-table class="table table-mg table-new-budgets">
                        <v-thead>
                            <v-tr>
                                <v-th></v-th>
                                <v-th class="text-center">{{
                                    $t("budget.color_and_id")
                                }}</v-th>
                                <v-th class="text-center">{{
                                    $t("budget.spanning_period_length")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.kwh_actual")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.kwh_budget")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.turnover_actual")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.turnover_budget")
                                }}</v-th>
                                <v-th v-if="ACL_RW"></v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr v-for="budget in budgets" :key="budget.id">
                                <v-td class="text-center">
                                    <div
                                        @click="setOnAll(budget.id)"
                                        class="m-click"
                                    >
                                        <i
                                            class="far fa-stamp text-muted fa-lg fa-fw"
                                            :title="
                                                $t(
                                                    'budget.set_this_budget_on_all_periods'
                                                )
                                            "
                                        ></i>
                                        <div class="text-muted small mt-1">
                                            {{
                                                $t(
                                                    "budget.set_this_budget_on_all_periods"
                                                )
                                            }}
                                        </div>
                                    </div>
                                </v-td>
                                <v-td
                                    class="text-center"
                                    :class="
                                        budget.id in budgetColors
                                            ? `color-${budgetColors[budget.id]}`
                                            : ''
                                    "
                                >
                                    #{{ budget.id }}
                                    <div class="small mt-1">
                                        {{ $t("budget.year") }}:
                                        {{ budget.year }}
                                    </div>
                                </v-td>
                                <v-td class="text-center">
                                    <template v-if="budget.id in budgetColors">
                                        {{
                                            $tc(
                                                "budget.N_months",
                                                budget.total_period_length
                                            )
                                        }}
                                    </template>
                                    <template v-else>
                                        ({{ $t("budget.not_assigned") }})
                                    </template>
                                </v-td>
                                <v-td class="text-right">{{
                                    $n(budget.actual_production_kwh)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.planned_production_kwh)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.actual_revenue, {
                                        style: "currency",
                                        currency: contract.currency
                                    })
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.planned_revenue, {
                                        style: "currency",
                                        currency: contract.currency
                                    })
                                }}</v-td>
                                <v-td class="text-right" v-if="ACL_RW">
                                    <i
                                        class="far fa-pencil text-muted fa-lg m-click fa-fw"
                                        @click="setContext(budget)"
                                    ></i>
                                </v-td>
                            </v-tr>
                        </v-tbody>
                        <v-tfoot>
                            <v-tr class="sum-line">
                                <v-td class="text-right" colspan="2">{{
                                    $t("TOTAL")
                                }}</v-td>
                                <v-td class="text-center">
                                    {{
                                        $tc(
                                            "budget.N_months",
                                            meta.stats.total_period_length
                                        )
                                    }}</v-td
                                >
                                <v-td class="text-right">{{
                                    $n(meta.stats.actual_production_kwh)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(meta.stats.planned_production_kwh)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(meta.stats.actual_revenue, {
                                        style: "currency",
                                        currency: contract.currency
                                    })
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(meta.stats.planned_revenue, {
                                        style: "currency",
                                        currency: contract.currency
                                    })
                                }}</v-td>
                                <v-td></v-td>
                            </v-tr>
                        </v-tfoot>
                    </v-table>
                </div>
            </template>

            <div v-else class="alert alert-warning alert-outline ml-4 mr-4">
                {{ $t("budget.no_data") }}
            </div>

            <portal to="modals">
                <v-budget-form
                    v-if="editing"
                    :context="editContext"
                    :contract="contract"
                    :year="year"
                    :hint-connected="connectedContext"
                    @close="editing = false"
                    @close-refresh="closeSave"
                />
            </portal>
        </div>
    </div>
</template>

<style lang="scss">
.sum-line {
    border-top: 3px solid $gray-600 !important;
    td {
        font-weight: bold;
    }
}
</style>
