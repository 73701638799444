<script>
export default {
    props: {
        s: {}
    },
    methods: {
        setContext(s) {
            this.$emit("click", s);
        }
    }
};
</script>

<template>
    <div style="white-space: nowrap">
        <button
            class="btn btn-sm btn-primary"
            @click="setContext(s)"
            v-if="s.status == 1"
        >
            <i class="far fa-pencil fa-lg m-click"></i>
        </button>
        <button
            class="btn btn-sm btn-primary"
            v-if="parseInt(s.subscription_type) !== 1"
            @click="
                $router.push({
                    name: 'CustomersTabSubscriptionDetails',
                    params: { subscription_id: s.id, customer_id: s.customer_id }
                })
            "
        >
            <i class="far fa-cog fa-lg"></i>
        </button>
        <button class="btn btn-sm btn-warning" v-if="s.status == 2" @click="$emit('relive', s.id)">
            <i class="far fa-retweet-alt fa-lg"></i>
        </button>
    </div>
</template>
