<script>
import { mapGetters } from "vuex";
import Cloner from "@/libraries/Cloner";
import MGConsts from "@/mixins/MGConsts";
import CustomerService from "@/services/CustomerService";
import Messages from "@/mixins/Messages";

export default {
    name: "VMilestoneForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        }
    },
    mixins: [MGConsts, Messages],
    data() {
        return {
            editing: false,
            loading: true,
            errors: null,
            customer: {
                id: "",
                active: "",
                number: "",
                name: "",
                vat_number: "",
                vat_zone: "",
                default_currency: "",
                address: "",
                postal_code: "",
                city: "",
                country: "",
                discount: "",
                lang: "",
                customer_subtype: "",
                internal_customer: ""
            }
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.INVOICE_MGMT);

        if (!this.context.empty) {
            // Edit
            this.editing = true;
            this.customer = Cloner.pickClone(this.context, [
                "id",
                "active",
                "number",
                "name",
                "vat_number",
                "vat_zone",
                "default_currency",
                "address",
                "postal_code",
                "city",
                "country",
                "discount",
                "lang",
                "customer_subtype",
                "internal_customer"
            ]);            
        } else {
            this.customer.internal_customer = "0";
        }

        this.loading = false;
    },
    methods: {
        /**
         * Save form
         */
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await CustomerService.PUT(this.customer);
                } else {
                    const r = await CustomerService.POST(this.customer);
                }
                this.$notify({
                    message: this.$t("customer.notification_customer_saved"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });
                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Delete
         */
        deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("milestones.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await MilestoneService.DELETE(
                                this.milestone.id
                            );
                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.errors = e.response.data.errors;
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error")
                                });
                            }
                        }
                    }
                });
            }
        }
    }
};
</script>

<template>
    <modal
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="budget-modal"
        size="xl"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("customer.edit") }}
            </template>
            <template v-else>
                {{ $t("customer.add") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm
                v-model="customer"
                @submit="saveForm"
                :errors="errors"
                :debounce="100"
            >
                <div class="row">
                    <div class="col-4">
                        <FormulateInput
                            name="number"
                            type="text"
                            :label="$t('customer.customer_number')"
                            :help="$t('customer.help_customer_number')"
                            validation="required"
                        />
                    </div>

                    <div class="col-8">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('customer.name')"
                            validation="required"
                        />
                    </div>

                    <div class="col-4">
                        <FormulateInput
                            name="vat_number"
                            type="text"
                            :label="$t('customer.vat_number')"
                            validation="required"
                        />
                    </div>

                    <div class="col-4">
                        <FormulateInput
                            type="groupdecimal"
                            :precision="2"
                            name="discount"
                            append="%"
                            :label="$t('customer.discount')"
                            :help="$t('customer.help_discount')"
                        />
                    </div>

                    <div class="col-4">
                        <FormulateInput
                            type="fselect"
                            :options="mgGeneralActive"
                            name="active"
                            :label="$t('customer.status')"
                            :placeholder="$t('customer.placeholder_status')"
                            validation="required"
                            :validation-name="$t('customer.status')"
                        />
                    </div>
                    <div class="col-4">
                        <FormulateInput
                            type="fselect"
                            :disabled="editing"
                            :options="mgCurrencies"
                            name="default_currency"
                            :placeholder="
                                $t('customer.placeholder_default_curency')
                            "
                            validation="required"
                            :label="$t('customer.default_currency')"
                            :validation-name="$t('customer.default_currency')"
                        />
                    </div>
                    <div class="col-4">
                        <FormulateInput
                            type="fselect"
                            :options="mgLangs"
                            name="lang"
                            :placeholder="$t('customer.placeholder_language')"
                            validation="required"
                            :label="$t('customer.language')"
                            :validation-name="$t('customer.language')"
                        />
                    </div>
                    <div class="col-4">
                        <FormulateInput
                            type="fselect"
                            :options="mgVatZones"
                            name="vat_zone"
                            :placeholder="$t('customer.placeholder_vat_zone')"
                            validation="required"
                            :label="$t('customer.vat_zone')"
                            :validation-name="$t('customer.vat_zone')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <FormulateInput
                            name="address"
                            type="textarea"
                            :label="$t('customer.address')"
                        />
                    </div>
                    <div class="col-4">
                        <FormulateInput
                            name="postal_code"
                            type="text"
                            :label="$t('customer.postal_code')"
                        />
                    </div>
                    <div class="col-4">
                        <FormulateInput
                            name="city"
                            type="text"
                            :label="$t('customer.city')"
                        />
                    </div>
                    <div class="col-4">
                        <FormulateInput
                            name="country"
                            type="text"
                            :label="$t('customer.country')"
                        />
                    </div>
                    <div class="col-4">
                        <FormulateInput
                            type="fselect"
                            :options="mgCustomerSubtype"
                            name="customer_subtype"
                            :placeholder="
                                $t('customer.placeholder_customer_subtype')
                            "
                            validation="required"
                            :label="$t('customer.customer_subtype')"
                            :validation-name="$t('customer.customer_subtype')"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-6 col-sm-12 col-xs-12 text-left">
                        <base-button 
                            type="danger"
                            v-if="false"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style></style>
