<script>
import dayjs from "dayjs";

import VContractConditionForm from "@/components/Forms/VContractConditionForm";
import ContractTimeline from "@/components/Momentum/NewContracts/ContractTimeline";
import TerminationBar from "@/components/Momentum/NewContracts/TerminationBar";
import ContractGeneralInfo from "@/components/Momentum/NewContracts/ContractGeneralInfo";
import ContractInvoices from "@/components/Momentum/NewContracts/ContractInvoices";
import ConditionsTable from "@/components/Momentum/NewContracts/ConditionsTable";
import ContractConditionService from "@/services/ContractConditionService";
import ContractDetailService from "@/services/ContractDetailService";
import ContractService from "@/services/ContractService";
import StatRow from "@/views/NewContracts/components/StatRow";
import LeftNavi from "@/views/NewContracts/components/LeftNavi";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        contract_id: {},
        customer_id: {}
    },
    components: {
        ContractTimeline,
        ConditionsTable,
        ContractGeneralInfo,
        VContractConditionForm,
        ContractInvoices,
        TerminationBar,
        StatRow,
        LeftNavi
    },
    data() {
        return {
            loading: true,
            loadingConditions: true,
            loadingDetails: true,
            conditions: [],
            details: [],
            meta: {},
            contract: {},
            displayYear: null,
            availableYears: [],
            showConditionModal: false,
            conditionContext: null,
            enableEditing: false
        };
    },
    computed: {
        currentYear() {
            return dayjs().format("YYYY");
        }
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        await this.fetchAvailableYears();

        const budget = this.$route.query.budget;
        if (budget !== undefined) {
            this.displayYear = budget;
        } else {
            this.displayYear = this.currentYear;
        }
        await this.fetchContract(false);
        await this.fetchConditions(false);
        await this.fetchDetails(this.displayYear, false);

        this.loading = false;
        this.loadingConditions = false;
        this.loadingDetails = false;

        if (budget !== undefined) {
            this.$nextTick(() => {
                const anchor = document.querySelector("#contract-budget-box");
                if (anchor) {
                    anchor.scrollIntoView({ behavior: "smooth" });
                }
            });
        }
    },
    methods: {
        async fetchAvailableYears() {
            try {
                const { data } = await ContractDetailService.GET_availableYears(
                    this.contract_id
                );
                this.availableYears = data.data;
            } catch (e) {
                console.log("fay, e", e);
            }
        },
        changeYear(newYear) {
            this.displayYear = newYear;
            this.reloadElements();
        },
        /**
         * Reload what is required after editing budgets/details
         */
        reloadElements() {
            this.fetchDetails(this.displayYear);
            EventBus.$emit("reload-budgets");
        },
        reloadContract() {
            this.fetchContract();
            this.fetchDetails(this.displayYear);
        },
        /**
         * Fetch the contract itself
         * @param loading
         */
        async fetchContract(loading = false) {
            try {
                this.loading = true;
                const { data } = await ContractService.GETS(this.contract_id);
                this.contract = data.data;
            } catch (e) {
                console.log("fctr", e);
            }

            this.loading = loading;
        },
        /**
         * Fetch contractl details - year is required
         * @param loading
         */
        async fetchDetails(year, loading = false) {
            try {
                this.loadingDetails = true;
                const { data } = await ContractDetailService.GET_forContract(
                    this.contract_id,
                    year
                );
                this.details = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd", e);
            }
            this.loadingDetails = loading;
        },
        /**
         * Fetch contract conditions
         * @param loading
         */
        async fetchConditions(loading = false) {
            try {
                this.loadingConditions = true;
                const { data } = await ContractConditionService.GET_forContract(
                    this.contract_id
                );
                this.conditions = data.data;
            } catch (e) {
                console.log("fc", e);
            }
            this.loadingConditions = loading;
        },
        /**
         * Edit / create contract condition
         * @param ctx
         */
        setConditionContext(ctx) {
            this.conditionContext = ctx;
            this.showConditionModal = true;
        },
        editCondition({ contract_condition }) {
            this.setConditionContext(contract_condition);
        },
        /**
         * Close the modal & reload conditions
         */
        closeReloadCondition() {
            this.fetchConditions();
            this.fetchDetails(this.displayYear);
            this.showConditionModal = false;
        }
    }
};
</script>

<template>
    <div
        class="container-fluid v-manage-contract"
        :class="{ 'contract-terminated': contract.has_termination }"
    >
        <div class="row card-wrapper">
            <!-- Contract general information -->
            <div class="col-12" v-if="!loading && contract">
                <termination-bar :contract="contract" />
            </div>
            <div class="col-12">
                <div class="mg-title mb-3" v-if="false">
                    <div class="mg-title__left">
                        <h1>{{ $t("contracts.contract_conditions") }}</h1>
                    </div>
                    <div class="mg-title__right" v-if="false">
                        <base-button
                            v-if="ACL_RW"
                            size="sm"
                            type="secondary"
                            @click="setContext({ empty: true })"
                            ><i class="far fa-plus"></i>
                            {{ $t("contracts.add_contract") }}</base-button
                        >
                    </div>
                </div>
                <card body-classes="px-0" mini-header-classes="text-center">
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else>
                        <contract-general-info
                            :contract="contract"
                            @reload-contract="reloadContract"
                        />
                    </template>
                </card>
            </div>
            <!-- Contract details / payment period -->
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>{{ $t("contracts.detailed_payment_periods") }}</h1>
                    </div>
                    <div class="mg-title__right" v-if="false">
                        <base-button
                            v-if="ACL_RW"
                            size="sm"
                            type="secondary"
                            @click="setContext({ empty: true })"
                            ><i class="far fa-plus"></i>
                            {{ $t("contracts.add_contract") }}</base-button
                        >
                    </div>
                </div>

                <v-loader :loading="loadingDetails" v-if="loadingDetails" />

                <template v-else>
                    <card
                        class="contract"
                        body-classes="px-0"
                        mini-header-classes="text-center"
                    >
                        <!-- HERE Header + btns -->
                        <div class="px-3">
                            <div class="row">
                                <!-- Left side -->
                                <div class="col-7">
                                    <left-navi
                                        :available-years="availableYears"
                                        :contract="contract"
                                        :display-year="displayYear"
                                        :meta="meta"
                                        @change-year="changeYear"
                                    />
                                </div>
                                <!-- Right side -->
                                <div class="col-5 text-right">
                                    <base-button
                                        v-if="contract.active == 1 && ACL_RW"
                                        :type="
                                            enableEditing ? 'danger' : 'primary'
                                        "
                                        size="sm"
                                        @click="enableEditing = !enableEditing"
                                        ><i
                                            class="far"
                                            :class="{
                                                'fa-pencil': !enableEditing,
                                                'fa-exclamation-triangle': enableEditing
                                            }"
                                        ></i>
                                        <template v-if="enableEditing">
                                            {{ $t("contract.disable_editing") }}
                                        </template>
                                        <template v-else>
                                            {{
                                                $t("contract.enable_editing")
                                            }}</template
                                        ></base-button
                                    >
                                </div>
                            </div>
                            <!-- .row -->
                            <stat-row :meta="meta" />
                        </div>

                        <!-- Contract details timeline + budgets (are inside) -->
                        <div class="text-center text-muted mb-4">
                            {{ $t("contract.notice_about_calculated_periods") }}
                        </div>
                        <contract-timeline
                            :contract-details="details"
                            :stats="meta"
                            :contract="contract"
                            :year="displayYear"
                            :enable-editing="enableEditing"
                            @reload="reloadElements"
                        />

                        <hr class="mt-5 mb-5" />

                        <!-- invoices -->
                        <contract-invoices
                            :year="displayYear"
                            :contract="contract"
                            :contract-details="details"
                        />

                        <div class="row" v-if="false">
                            <div
                                class="col-1"
                                v-for="(color, index) in colors"
                                :key="index"
                                :style="{ 'background-color': color }"
                            >
                                #{{ color }}
                            </div>
                        </div>
                    </card>
                </template>

                <card body-classes="px-0" v-else>
                    <div class="pb-mg">
                        <div class="nice-info">
                            {{ $t("contract.no_contracts") }}
                        </div>
                    </div>
                </card>
            </div>
            <!-- Contract conditions -->
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>{{ $t("contracts.contract_conditions") }}</h1>
                    </div>
                    <div class="mg-title__right" v-if="true">
                        <base-button
                            v-if="ACL_RW"
                            size="sm"
                            type="secondary"
                            @click="setConditionContext({ empty: true })"
                            ><i class="far fa-plus"></i>
                            {{
                                $t("contracts.add_contract_condition")
                            }}</base-button
                        >
                    </div>
                </div>
                <card body-classes="px-0" mini-header-classes="text-center">
                    <v-loader
                        :loading="loadingConditions"
                        v-if="loadingConditions"
                    />
                    <template v-else>
                        <conditions-table
                            :contract-conditions="conditions"
                            :contract-type="contract.contract_type"
                            @edit="editCondition"
                        />
                    </template>
                </card>
            </div>
        </div>

        <div class="row card-wrapper"></div>
        <!-- .row for contracts -->

        <portal to="modals">
            <v-contract-condition-form
                v-if="showConditionModal"
                :context="conditionContext"
                :contract="contract"
                @close="showConditionModal = false"
                @close-reload="closeReloadCondition"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-manage-contract {
    .contract-q-dash {
        .hinner-dash-card__left {
            font-size: 16px;
        }
    }

    &.contract-terminated {
        .card-body {
            border: 1px solid $danger !important;
            border-radius: 6px;
        }
    }
}
</style>
