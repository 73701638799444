<script>
import TravelPriceService from "@/services/TravelPriceService";
import MunicipalityService from "@/services/MunicipalityService";
import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";
import ProjectService from "@/services/ProjectService";
import CurrencyService from "@/services/CurrencyService";
import PlanningTypeService from "@/services/PlanningTypeService";
import Cloner from "@/libraries/Cloner";
import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VProjectForm",
    mixins: [AppCfg],
    components: {},
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        },
        customer_id: {
            description: "Customer ID",
            default: null
        }
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,

            // section
            section_plan: false,
            section_customer: false,
            km_price: "",

            // Relations
            customers: [],
            project_types: [],
            project_interfaces: [],
            users: [],
            status: [],
            project_models: [],
            currencies: [],
            planning_types: [],
            discounts: {},
            showDiscountNote: false,
            municipalities: {},

            // Project
            project: {
                id: null,
                customer_id: null,
                project_type: null,
                user_id: null,
                status: null,
                title: null,
                description: null,
                invoicing_description: null,
                project_model: null,
                project_interface: null,
                planning_type_id: null,
                currency: null,
                budget_amount: null,
                from_date: null,
                deadline_date: null,
                cust_contact_person: null,
                cust_case_number: null,
                cust_email: null,
                cust_phone: null,
                km_price: null,
                use_tax_km_price: null,
                discount: null,
                travel_is_billable: 1,
                invoice_setup: null,
                municipality_id: null,
                exclude_portfolio: null
            }
        };
    },
    computed: {
        // Pick for watch -> apply discount when creating new
        selectedCustomer: function() {
            return this.project.customer_id;
        },
        selectedUser() {
            return this.project.user_id;
        },
        invoiceSetups() {
            return this.appCfgRead("Projects.invoice_setup", true);
        },
        useTaxKmPrice() {
            return this.project.use_tax_km_price;
        },
        excludes() {
            return [
                { label: this.$t("no"), value: null },
                { label: this.$t("yes"), value: "1" }
            ];
        }
    },
    watch: {
        useTaxKmPrice: function(newV) {
            if (parseInt(newV) === 1) {
                this.project.km_price = null;
            }
        },
        // Watcher for applying customer discount if defined (creation only)
        selectedCustomer: function(c_id) {
            if (c_id && this.editing == false) {
                this.fixCurrency();

                let v = this.discounts.find(e => {
                    return e.id == c_id;
                });

                if (v.discount > 0) {
                    this.project.discount = v.discount;
                    this.showDiscountNote = true;
                } else {
                    this.showDiscountNote = false;
                }
            }
        },
        selectedUser: function(nId, oId) {
            if (parseInt(nId) > 0) {
                this.matchInvoiceSetup(nId);
            }
        }
    },
    async created() {
        // Load all resources
        this.loading = true;
        await this.fetchMunicipalities();
        await this.fetchUsers();
        await this.fetchEntityResources();
        await this.fetchCustomers();
        await this.fetchCurrencies();
        await this.fetchDiscounts();
        await this.fetchPlanningTypes();

        // Insert context
        if (!this.context.empty) {
            this.editing = true;
            this.project = Cloner.pickClone(this.context, [
                "id",
                "customer_id",
                "project_type",
                "user_id",
                "status",
                "title",
                "description",
                "invoicing_description",
                "project_model",
                "project_interface",
                "planning_type_id",
                "currency",
                "budget_amount",
                "from_date",
                "deadline_date",
                "cust_contact_person",
                "cust_case_number",
                "cust_email",
                "cust_phone",
                "km_price",
                "use_tax_km_price",
                "discount",
                "travel_is_billable",
                "invoice_setup",
                "municipality_id",
                "exclude_portfolio"
            ]);

            // Open up sections if data defined on editing
            if (this.project.deadline_date || this.project.from_date) {
                this.section_plan = true;
            }

            if (
                this.cust_contact_person ||
                this.cust_case_number ||
                this.cust_email ||
                this.cust_phone
            ) {
                this.section_customer = true;
            }
        } else {
            if (this.customer_id != null) {
                this.project.customer_id = this.customer_id;
                this.project.km_price = this.currentCaseRate;
            }

            this.project.status = "1";
            this.project.use_tax_km_price = "1";
        }

        this.loading = false;
    },
    methods: {
        async fetchMunicipalities() {
            try {
                const { data } = await MunicipalityService.GET_dropdown();
                this.municipalities = data.data;
                this.municipalities.unshift({
                    value: "",
                    label: this.$t("planning.all_municipalities")
                });
            } catch (e) {
                console.log("f mun e", e);
            }
        },
        async fetchTravelPrice(currency) {
            try {
                const { data } = await TravelPriceService.GET_currentCaseRate(
                    currency
                );
                this.km_price = data.data;
            } catch (e) {
                console.log("travel price e ", e);
            }
        },
        async matchInvoiceSetup(user_id) {
            try {
                const { data } = await UserService.GET_matchInvoiceSetup(
                    user_id
                );
                this.$set(this.project, "invoice_setup", data.data);
            } catch (e) {}
        },
        /**
         * Save form
         */
        async saveForm() {
            let project_id = 0;

            try {
                if (this.editing) {
                    const r = await ProjectService.PUT(this.project);
                    project_id = r.data.data.id;
                } else {
                    const r = await ProjectService.POST(this.project);
                    project_id = r.data.data.id;
                }

                this.$emit("close-refresh");

                if (this.editing) {
                    this.$notify({
                        message: this.$t("project.notify_project_updated"),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });
                } else {
                    this.$notify({
                        message: this.$t("project.notify_project_created"),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });

                    this.$router.push({
                        name: "ProjectDisplay",
                        params: { project_id }
                    });
                }
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        async fetchPlanningTypes() {
            try {
                const { data } = await PlanningTypeService.GET_dropdown();
                this.planning_types = data.data;
            } catch (e) {}
        },
        /**
         * Fetch users
         */
        async fetchUsers(loading = true) {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Fetch discounts
         */
        async fetchDiscounts(loading = true) {
            try {
                this.loading = true;
                const r = await CustomerService.GET_discounts();
                this.discounts = r.data.data;
            } catch (e) {
                console.log("Discounts exception", e);
            }

            this.loading = loading;
        },
        /**
         * Fetch case enums
         */
        async fetchEntityResources(loading = true) {
            this.project_models = this.appCfgRead(
                "Projects.project_model_form",
                true
            );
            this.project_types = this.appCfgRead("Projects.project_type", true);
            this.status = this.appCfgRead("Projects.status", true);
            this.project_interfaces = this.appCfgRead(
                "Projects.project_interface",
                true
            );
        },
        /**
         * Get customers
         */
        async fetchCustomers(loading = true) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Get currency list
         */
        async fetchCurrencies(loading = true) {
            try {
                this.loading = true;
                const r = await CurrencyService.dropdownCodes({
                    fview: "active"
                });
                this.currencies = r.data.data;
            } catch (e) {
                console.log("fetchCurrencies exception", e);
            }

            this.loading = loading;
        },
        async fixCurrency() {
            try {
                const { data } = await CustomerService.GETS(
                    this.project.customer_id
                );
                this.$set(this.project, "currency", data.data.default_currency);

                //await this.fetchTravelPrice(data.data.default_currency);
                //this.$set(this.project, "km_price", this.km_price);
            } catch (e) {}
        }
    }
};
</script>

<template>
    <modal
        :loading="loading"
        size="xl"
        :show="true"
        @close="$emit('close')"
        class="project-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("contract.edit_project") }}
            </template>
            <template v-else>
                {{ $t("contract.create_project") }}
            </template>
        </template>
        <template slot="default" v-if="!loading">
            <FormulateForm
                v-model="project"
                @submit="saveForm"
                :errors="errors"
                :debounce="100"
            >
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <FormulateInput
                            type="multiselect"
                            :options="customers"
                            name="customer_id"
                            :validation-name="$t('project.customer')"
                            :label="$t('project.customer')"
                            :placeholder="
                                $t('project.placeholder_select_customer')
                            "
                            validation="required"
                            :disabled="editing"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-12">
                        <FormulateInput
                            type="multiselect"
                            :options="project_interfaces"
                            name="project_interface"
                            :validation-name="$t('project.project_interface')"
                            :label="$t('project.project_interface')"
                            :placeholder="
                                $t(
                                    'project.placeholder_select_project_interface'
                                )
                            "
                            validation="required"
                            :disabled="editing"
                        />
                    </div>
                    <template v-if="project.project_interface == 2">
                        <div class="col-12">
                            <FormulateInput
                                type="multiselect"
                                :options="planning_types"
                                validation="required"
                                name="planning_type_id"
                                :disabled="editing"
                                :validation-name="$t('project.planning_type')"
                                :label="$t('project.planning_type')"
                                :placeholder="
                                    $t(
                                        'project.placeholder_select_planning_type'
                                    )
                                "
                                :help="$t('project.help_planning_type')"
                            />
                        </div>
                        <div class="col-12">
                            <FormulateInput
                                type="multiselect"
                                :options="excludes"
                                name="exclude_portfolio"
                                :validation-name="
                                    $t('project.exclude_from_portfolio')
                                "
                                :label="$t('project.exclude_from_portfolio')"
                                :placeholder="
                                    $t(
                                        'project.placeholder_select_exclude_from_portfolio'
                                    )
                                "
                                :help="
                                    $t('project.help_exclude_from_portfolio')
                                "
                            />
                        </div>
                    </template>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6">
                        <FormulateInput
                            type="select"
                            :options="project_types"
                            name="project_type"
                            :validation-name="$t('project.case_type')"
                            :label="$t('project.case_type')"
                            validation="required"
                            :placeholder="$t('project.placeholder_case_type')"
                        />
                    </div>
                    <div class="col-12 col-sm-6">
                        <FormulateInput
                            type="select"
                            :options="status"
                            name="status"
                            :validation-name="$t('project.status')"
                            :label="$t('project.status')"
                            validation="required"
                            :placeholder="$t('project.placeholder_status')"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-7">
                        <FormulateInput
                            type="multiselect"
                            :options="users"
                            name="user_id"
                            :validation-name="$t('project.responsible_user')"
                            :label="$t('project.responsible_user')"
                            validation="required"
                            :placeholder="
                                $t('project.placeholder_responsible_user')
                            "
                        />
                    </div>
                    <div class="col-12 col-sm-5">
                        <FormulateInput
                            type="select"
                            :options="invoiceSetups"
                            name="invoice_setup"
                            :validation-name="$t('project.invoice_setup')"
                            :label="$t('project.invoice_setup')"
                            validation="required"
                            :placeholder="
                                $t('project.placeholder_invoice_setup')
                            "
                        />
                    </div>
                </div>

                <div class="row">
                    <div
                        :class="
                            project.project_interface == 2 ? 'col-7' : 'col-12'
                        "
                    >
                        <FormulateInput
                            type="text"
                            name="title"
                            :validation-name="$t('project.title')"
                            :label="$t('project.title_case_name')"
                            validation="required"
                            :placeholder="$t('project.placeholder_case_title')"
                        />
                    </div>
                    <div class="col-5" v-if="project.project_interface == 2">
                        <FormulateInput
                            type="select"
                            name="municipality_id"
                            :options="municipalities"
                            :validation-name="$t('project.municipality')"
                            :label="$t('project.municipality')"
                            validation="required"
                            :placeholder="
                                $t('project.placeholder_municipality')
                            "
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="textarea"
                            name="description"
                            :validation-name="$t('project.description')"
                            :label="$t('project.description')"
                            :placeholder="$t('project.placeholder_description')"
                        />
                    </div>
                </div>

                <h3 class="sub-heading">
                    {{ $t("project.budget_and_invoicing") }}
                </h3>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="textarea"
                            name="invoicing_description"
                            :validation-name="
                                $t('project.invoicing_description')
                            "
                            :label="$t('project.invoicing_description')"
                            :placeholder="
                                $t('project.placeholder_invoicing_description')
                            "
                        />
                    </div>

                    <div class="col-12 col-md-6">
                        <FormulateInput
                            type="select"
                            name="project_model"
                            :options="project_models"
                            :label="$t('project.settlement_methods')"
                            :validation-name="$t('project.settlement_methods')"
                            :placeholder="
                                $t('project.placeholder_settlement_methods')
                            "
                            validation="required"
                            :disabled="editing"
                        />
                    </div>

                    <div class="col-12 col-md-6">
                        <FormulateInput
                            name="currency"
                            type="select"
                            :options="currencies"
                            :label="$t('project.currency')"
                            :validation-name="$t('project.currency')"
                            :placeholder="$t('project.select_currency')"
                            validation="required"
                            :disabled="true"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <FormulateInput
                            type="currency"
                            :currency="project.currency"
                            name="budget_amount"
                            :label="$t('project.budget_amount')"
                            :validation-name="$t('project.budget_amount')"
                            :placeholder="
                                $t('project.placeholder_budget_amount')
                            "
                        />
                    </div>

                    <div class="col-12 col-md-6">
                        <FormulateInput
                            type="groupdecimal"
                            append="%"
                            name="discount"
                            validation="number|min:0|max:100"
                            :label="$t('project.discount')"
                            :validation-name="$t('project.discount')"
                            :placeholder="$t('project.placeholder_discount')"
                            :help="
                                showDiscountNote
                                    ? $t(
                                          'project.help_discount_taken_from_customer_settings'
                                      )
                                    : ''
                            "
                        />
                    </div>
                    <div class="col-12 col-md-4 travel-billable">
                        <FormulateInput
                            type="fswitch"
                            name="travel_is_billable"
                            :label="$t('project.default_travel_is_billable')"
                            :validation-name="
                                $t('project.default_travel_is_billable')
                            "
                            :placeholder="
                                $t(
                                    'project.placeholder_default_travel_is_billable'
                                )
                            "
                        />
                    </div>
                    <div class="col-12 col-md-4 travel-billable">
                        <FormulateInput
                            type="fswitch"
                            name="use_tax_km_price"
                            :label="$t('project.use_tax_km_price')"
                            :validation-name="$t('project.use_tax_km_price')"
                            :placeholder="
                                $t('project.placeholder_use_tax_km_price')
                            "
                            :help="$t('project.help_use_tax_km_price')"
                        />
                    </div>

                    <div class="col-12 col-md-4">
                        <FormulateInput
                            type="currency"
                            :currency="project.currency"
                            name="km_price"
                            :label="$t('project.km_price')"
                            :validation-name="$t('project.km_price')"
                            :placeholder="$t('project.placeholder_km_price')"
                            :disabled="!!project.use_tax_km_price"
                        />
                    </div>
                </div>

                <!-- Section plan -->
                <div class="text-primary mb-3" v-if="!section_plan">
                    <span @click="section_plan = true" class="m-click">
                        <i class="far fa-plus"></i>
                        {{ $t("project.add_plan_section") }}
                    </span>
                </div>
                <template v-else>
                    <h3 class="sub-heading">
                        {{ $t("project.deadlines") }}
                    </h3>

                    <div class="row">
                        <div class="col-12 col-md-4">
                            <FormulateInput
                                type="date"
                                name="from_date"
                                :label="$t('project.start_date')"
                                :validation-name="$t('project.start_date')"
                                :placeholder="
                                    $t('project.placeholder_start_date')
                                "
                            />
                        </div>
                        <div class="col-12 col-md-4">
                            <FormulateInput
                                type="date"
                                name="deadline_date"
                                :label="$t('project.deadline_date')"
                                :validation-name="$t('project.deadline_date')"
                                :placeholder="
                                    $t('project.placeholder_deadline_date')
                                "
                            />
                        </div>
                    </div>
                </template>

                <div class="text-primary" v-if="!section_customer">
                    <span class="m-click" @click="section_customer = true">
                        <i class="far fa-plus"></i>
                        {{ $t("project.add_customer_section") }}
                    </span>
                </div>
                <template v-else>
                    <h3 class="sub-heading">
                        {{ $t("project.customer_details") }}
                    </h3>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <FormulateInput
                                type="text"
                                name="cust_contact_person"
                                :label="$t('project.customer_contact_person')"
                                :validation-name="
                                    $t('project.customer_contact_person')
                                "
                                :placeholder="
                                    $t(
                                        'project.placeholder_customer_contact_person'
                                    )
                                "
                            />
                        </div>
                        <div class="col-12 col-md-6">
                            <FormulateInput
                                type="text"
                                name="cust_case_number"
                                :label="$t('project.customer_case_number')"
                                :validation-name="
                                    $t('project.customer_case_number')
                                "
                                :placeholder="
                                    $t(
                                        'project.placeholder_customer_case_number'
                                    )
                                "
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <FormulateInput
                                type="text"
                                name="cust_email"
                                :label="$t('project.customer_email')"
                                :validation-name="$t('project.customer_email')"
                                :placeholder="
                                    $t('project.placeholder_customer_email')
                                "
                            />
                        </div>
                        <div class="col-12 col-md-6">
                            <FormulateInput
                                type="text"
                                name="cust_phone"
                                :label="$t('project.customer_phone')"
                                :validation-name="$t('project.customer_phone')"
                                :placeholder="
                                    $t('project.placeholder_customer_phone')
                                "
                            />
                        </div>
                    </div>
                </template>

                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style></style>
